import * as React from "react";
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { IconButton } from "@mui/material";
// import Divider from "@mui/material/Divider";
// import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";

import { useContext } from "react";
import routes_logout from "routes_logout";
import DefaultNavbarLogOut from "examples/Navbars/DefaultNavbarLogOut";
import { AuthContext } from "context";

const style_bottom = {
  py: 2.3,
  width: "100%",
  borderRadius: 0,
  border: "1px solid",
  borderColor: "divider",
  backgroundColor: "background.paper",
  mt: 20,
};

function Contents6() {
  const authContext = useContext(AuthContext);

  return (
    <>
      {authContext.isAuthenticated ? (
        <>
          <DefaultNavbarLogOut routes={routes_logout} sticky />
        </>
      ) : (
        <>
          <DefaultNavbar routes={routes} sticky />
        </>
      )}
      <Container>
        <Grid container spacing={3} item xs={10} lg={8} mx="auto">
          <MKBox flexDirection={{ xs: "column", md: "row" }}>
            <MKTypography
              // component="h6"
              variant="body2"
              color="primary"
              opacity={0.7}
              textTransform="none"
              fontWeight="bold"
              mt={40}
              style={{ userSelect: "none" }}
            >
              Knowledge base
            </MKTypography>
            <MKTypography variant="h2" mb={3} color="black" style={{ userSelect: "none" }}>
              Advancing Clinical Research: Exploring Study Designs and Methodologies in the Age of
              Real-World Data
            </MKTypography>
            <MKTypography
              variant="body1_w"
              color="black"
              style={{ userSelect: "none" }}
              align="justify"
            >
              <br />
              <br />
              Hey there, research enthusiasts! Lyndon, your data science sidekick from DATAIZE, back
              again with another deep dive into the world of clinical research. Last time, we
              covered the fundamentals and significance of clinical research, especially in the
              context of Real-World Data (RWD) and Real-World Evidence (RWE). Today, we&apos;re
              taking it up a notch and exploring the various study designs and methodologies used in
              clinical research.
              <br />
              <br />
              First up, let&apos;s talk about the two main categories of clinical research:
              observational studies and interventional studies. In observational studies,
              researchers simply observe participants without intervening. Cohort studies and
              case-control studies are prime examples of observational study designs.
              <br />
              <br />
              Cohort studies follow two groups of people over time - one exposed to a particular
              factor and one not exposed - to compare disease incidence and uncover potential causal
              relationships. On the flip side, case-control studies start with a group of patients
              who already have a disease and a healthy control group, then look back to compare
              their past exposures and identify risk factors.
              <br />
              <br />
              Now, interventional studies are where researchers actively introduce an intervention,
              like a new treatment. The gold standard here is the randomized controlled trial (RCT).
              In an RCT, participants are randomly assigned to either the treatment group or the
              control group, allowing researchers to rigorously assess the efficacy and safety of
              the intervention.
              <br />
              <br />
              But here&apos;s the thing - RCTs are conducted under strict, controlled conditions
              that might not perfectly mirror real-world clinical settings. That&apos;s where RWD
              and RWE come in clutch. Observational studies leveraging RWD can provide a more
              realistic assessment of treatment effectiveness and safety in the wild.
              <br />
              <br />
              For instance, a cohort study using electronic health record data could investigate the
              long-term side effects of a medication. Or a case-control study tapping into mobile
              health app data might explore how certain lifestyle factors influence disease risk.
              <br />
              <br />
              Of course, RWD-based studies come with their own challenges, like ensuring data
              quality and controlling for bias. But with advancements in statistical techniques and
              AI, we&apos;re getting better at tackling these hurdles. By combining the strengths of
              RCTs and RWE studies, we can generate even more robust and clinically relevant
              evidence.
              <br />
              <br />
              So there you have it, folks - a whirlwind tour of clinical research designs and
              methodologies, with a special focus on RWD and RWE. Each study design has its own pros
              and cons, so it&apos;s all about choosing the right approach for your research
              question and circumstances. As new methodologies and data strategies continue to
              emerge, clinical research will keep evolving to meet the needs of patients and
              healthcare providers.
              <br />
              <br />
              Join me next time as we delve into the crucial topic of ethical considerations in
              clinical research.
              <br />
              <br />
              Until then, stay curious, stay innovative, and most importantly, stay healthy!
            </MKTypography>
          </MKBox>
        </Grid>
      </Container>
      <List sx={{ ...style_bottom }}>
        <ListItem>
          <ListItemText>
            {" "}
            <Container Width={3}>
              <Grid container spacing={0} display="flex-start" justifyContent="space-between">
                <Link path to="/blogs/bloglist/contents5">
                  <IconButton aria-label="Proceed">
                    <ArrowBackIcon />
                    <MKTypography variant="body2">Previous Contents</MKTypography>
                  </IconButton>
                </Link>
                <Link path to="/blogs/bloglist/contents7">
                  <IconButton aria-label="Proceed">
                    <MKTypography variant="body2">Next Contents</MKTypography>
                    <ArrowForwardIcon />
                  </IconButton>
                </Link>
              </Grid>
            </Container>
          </ListItemText>
        </ListItem>
      </List>
      {/* <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox> */}
    </>
  );
}

export default Contents6;
