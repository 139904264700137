import * as React from "react";
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { IconButton } from "@mui/material";
// import Divider from "@mui/material/Divider";
// import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";

import { useContext } from "react";
import routes_logout from "routes_logout";
import DefaultNavbarLogOut from "examples/Navbars/DefaultNavbarLogOut";
import { AuthContext } from "context";

const style_bottom = {
  py: 2.3,
  width: "100%",
  borderRadius: 0,
  border: "1px solid",
  borderColor: "divider",
  backgroundColor: "background.paper",
  mt: 20,
};

function Contents7() {
  const authContext = useContext(AuthContext);

  return (
    <>
      {authContext.isAuthenticated ? (
        <>
          <DefaultNavbarLogOut routes={routes_logout} sticky />
        </>
      ) : (
        <>
          <DefaultNavbar routes={routes} sticky />
        </>
      )}
      <Container>
        <Grid container spacing={3} item xs={10} lg={8} mx="auto">
          <MKBox flexDirection={{ xs: "column", md: "row" }}>
            <MKTypography
              // component="h6"
              variant="body2"
              color="primary"
              opacity={0.7}
              textTransform="none"
              fontWeight="bold"
              mt={40}
              style={{ userSelect: "none" }}
            >
              Knowledge base
            </MKTypography>
            <MKTypography variant="h2" mb={3} color="black" style={{ userSelect: "none" }}>
              Ethical Considerations in Clinical Research: Navigating Participant Rights and Data
              Privacy
            </MKTypography>
            <MKTypography
              variant="body1_w"
              color="black"
              style={{ userSelect: "none" }}
              align="justify"
            >
              <br />
              <br />
              Hey there, research revolutionaries! Lyndon here, your data science companion from
              DATAIZE. Today, we&apos;re tackling a crucial topic in clinical research: ethical
              considerations. We&apos;ll be exploring the ethical aspects of clinical studies,
              informed consent, participant selection criteria, and the role of Institutional Review
              Boards (IRBs). And, of course, we&apos;ll be keeping our Real-World Data (RWD) and
              Real-World Evidence (RWE) lenses on throughout.
              <br />
              <br />
              When it comes to clinical research, protecting participants&apos; rights, safety, and
              privacy is paramount. That&apos;s why international ethical guidelines, like the
              Declaration of Helsinki, are in place. Every clinical study must adhere to these
              ethical principles religiously.
              <br />
              <br />
              First up, let&apos;s talk about informed consent - the bedrock of ethical clinical
              research. This is the process of thoroughly explaining the study&apos;s purpose,
              procedures, potential risks, and benefits to participants and obtaining their
              voluntary agreement to take part. Even in RWD-based research, obtaining individual
              consent is ideal whenever possible. However, when using anonymized data, getting
              consent might not always be feasible. In such cases, researchers can seek a waiver
              from the IRB.
              <br />
              <br />
              Fair and ethical participant selection is another key issue. Inclusion and exclusion
              criteria should be based on sound scientific rationale and aligned with the
              study&apos;s objectives. We must avoid unjustly excluding specific groups or
              inappropriately including vulnerable populations. RWE studies have the advantage of
              reflecting diverse, real-world patient populations, but we still need to carefully
              consider data representativeness and potential biases.
              <br />
              <br />
              When it comes to data privacy, the US, EU, and South Korea all have stringent
              regulations in place. The US has HIPAA, the EU has GDPR, and South Korea has its
              Personal Information Protection Act. Clinical researchers must comply with these laws
              and safeguard participants&apos; personal information. When dealing with RWD, extra
              precautions like de-identification and anonymization are crucial.
              <br />
              <br />
              This is where IRBs come in - they&apos;re the watchdogs of research ethics. IRBs
              review study protocols to ensure they&apos;re ethically and scientifically sound and
              oversee the conduct of the research. RWE studies also require IRB approval, and
              researchers must adhere to IRB guidelines on data usage.
              <br />
              <br />
              So there you have it, folks - a deep dive into the ethical considerations of clinical
              research, with a special focus on RWD and RWE. Upholding research ethics is essential
              for protecting participants&apos; rights and ensuring the integrity of our studies. As
              we embrace new technologies and expand data utilization, our ethical standards must
              evolve in tandem.
              <br />
              <br />
              Join me next time as we explore how cutting-edge technologies are transforming
              clinical research and what the future might hold.
              <br />
              <br />
              Until then, stay curious, stay ethical, and most importantly, stay healthy!
            </MKTypography>
          </MKBox>
        </Grid>
      </Container>
      <List sx={{ ...style_bottom }}>
        <ListItem>
          <ListItemText>
            {" "}
            <Container Width={3}>
              <Grid container spacing={0} display="flex-start" justifyContent="space-between">
                <Link path to="/blogs/bloglist/contents6">
                  <IconButton aria-label="Proceed">
                    <ArrowBackIcon />
                    <MKTypography variant="body2">Previous Contents</MKTypography>
                  </IconButton>
                </Link>
                <Link path to="/blogs/bloglist/contents8">
                  <IconButton aria-label="Proceed">
                    <MKTypography variant="body2">Next Contents</MKTypography>
                    <ArrowForwardIcon />
                  </IconButton>
                </Link>
              </Grid>
            </Container>
          </ListItemText>
        </ListItem>
      </List>
      {/* <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox> */}
    </>
  );
}

export default Contents7;
