import { useContext, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import Checkbox from "@mui/material/Checkbox";
import { InputLabel } from "@mui/material";

import AuthService from "services/auth-service";
import { AuthContext } from "context";

// Authentication layout components
import SimpleLayout2 from "pages/Authentication/components/SimpleLayout2";

function SignUpSimple() {
  const authContext = useContext(AuthContext);

  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    valid: "",
    password: "",
    confirmPass: "",
    agree: false,
  });

  const [errors, setErrors] = useState({
    nameError: false,
    emailError: false,
    validError: false,
    passwordError: false,
    confirmPassError: false,
    agreeError: false,
    emailTaken: false,
  });

  // const changeHandler = (e) => {
  //   setInputs({
  //     ...inputs,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const changeHandler = (e) => {
    const { name, value } = e.target;

    // 모든 입력 필드에 대한 상태 업데이트
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));

    // 'email' 필드에 대해서만 추가적인 상태 업데이트
    // if (name === "email") {
    //   setEmail(value);
    // }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const mailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (inputs.name.trim().length === 0) {
      setErrors({ ...errors, nameError: true });
      return;
    }

    if (inputs.email.trim().length === 0 || !inputs.email.trim().match(mailFormat)) {
      setErrors({ ...errors, emailError: true });
      return;
    }

    if (inputs.valid !== "0308737") {
      setErrors({ ...errors, validError: true });
      return;
    }

    if (inputs.password.trim().length < 8) {
      setErrors({ ...errors, passwordError: true });
      return;
    }

    if (inputs.confirmPass.trim() !== inputs.password.trim()) {
      setErrors({ ...errors, confirmPassError: true });
      return;
    }

    if (inputs.agree === false) {
      setErrors({ ...errors, agreeError: true });
      return;
    }

    // here will be the post action to add a user to the db
    const newUser = { name: inputs.name, email: inputs.email, password: inputs.password };
    const myData = {
      data: {
        type: "users",
        attributes: { ...newUser, password_confirmation: newUser.password },
      },
    };

    try {
      const response = await AuthService.register(myData);
      authContext.login(response.access_token);
    } catch (err) {
      setErrors({ ...errors, emailTaken: true });
      console.error(err);
      return null;
    }

    setInputs({
      name: "",
      email: "",
      valid: "",
      password: "",
      confirmPass: "",
      agree: false,
    });

    setErrors({
      nameError: false,
      emailError: false,
      validError: false,
      passwordError: false,
      confirmPassError: false,
      agreeError: false,
      emailTaken: false,
    });
  };

  const baseUrl = "https://www.dataize.io/user-api/email/sendEmail";
  // const [email, setEmail] = useState("");
  // const [subject, setSubject] = useState("");
  // const [message, setMessage] = useState("");

  const emailHtml = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Welcome to DATAIZE AI-Scope</title>
  <style>
      body {
          font-family: Arial, sans-serif;
          margin: 10;
          padding: 10;
          background-color: #ffffff;
      }
      .container {
          max-width: 800px;
          margin: 20px auto;
          background: #ffffff;
          border-radius: 8px;
          overflow: hidden;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
      .header {
          background-color: #007bff;
          color: #ffffff;
          padding: 20px;
          text-align: center;
      }
      .content {
          padding: 30px;
          line-height: 1.6;
          color: #333;
      }
      .footer {
          background-color: #f4f4f4;
          color: #888;
          text-align: left;
          padding: 30px;
          font-size: 1em;
      }
  </style>
  </head>
  <body>
  <div class="container">
      <div class="header">
          <h1>Welcome to the DATAIZE AI-Scope!</h1>
      </div>
      <div class="content">
          <p>Thanks for joining us! We are happy to have you as a member of our growing community of data science innovators changing the world.</p>
          <p>Thanks again for subscribing. If you have any questions or comments, feel free to contact us. We look forward to keeping in touch!</p>
      </div>
      <div class="footer">
          Best regards,<br>
          The DATAIZE Innovation Team
      </div>
  </div>
  </body>
  </html>
  `;

  const sendEmail = async () => {
    let dataSend = {
      email: inputs.email,
      subject: "ai scope 회원가입 인증 메일 입니다",
      message: "인증번호 [0308737]를 입력해주세요.",
    };
    try {
      const response = await fetch(`${baseUrl}`, {
        method: "POST",
        body: JSON.stringify(dataSend),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      console.log(response);
      if (response.status > 199 && response.status < 300) {
        alert("귀하의 이메일로 인증번호가 전송되었습니다.");
      } else {
        // 에러 처리
        alert("에러가 발생하였습니다.");
      }
    } catch (error) {
      // 네트워크 에러 처리
      console.error("Fetch error: ", error);
    }
  };

  const sendEmail_complete = async () => {
    let dataSend = {
      email: inputs.email,
      subject: "ai scope 회원가입이 완료되었습니다.",
      message: emailHtml,
    };
    try {
      const response = await fetch(`${baseUrl}`, {
        method: "POST",
        body: JSON.stringify(dataSend),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      console.log(response);
    } catch (error) {
      // 네트워크 에러 처리
      console.error("Fetch error: ", error);
    }
  };

  return (
    <SimpleLayout2>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          pt={2.5}
          pb={2.875}
          px={2.5}
          textAlign="center"
          lineHeight={1}
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MKTypography>
          <MKTypography variant="button" color="white">
            Enter your email and password to register
          </MKTypography>
        </MKBox>
        <MKBox p={3}>
          <MKBox component="form" role="form" method="submit" onSubmit={submitHandler}>
            <MKBox mb={2}>
              <MKInput
                type="text"
                label="Name"
                variant="standard"
                fullWidth
                name="name"
                value={inputs.name}
                onChange={changeHandler}
                error={errors.nameError}
                inputProps={{
                  autoComplete: "name",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
              {errors.nameError && (
                <MKTypography variant="caption" color="error" fontWeight="light">
                  The name can not be empty
                </MKTypography>
              )}
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                name="email"
                value={inputs.email}
                onChange={changeHandler}
                // onChange={(e) => setEmail(e.target.value)}
                error={errors.emailError}
                inputProps={{
                  autoComplete: "email",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
              {errors.emailError && (
                <MKTypography variant="caption" color="error" fontWeight="light">
                  The email must be valid
                </MKTypography>
              )}
            </MKBox>
            <MKButton variant="gradient" color="info" fullWidth onClick={() => sendEmail()}>
              Verification
            </MKButton>
            <MKBox mb={2}>
              <MKInput
                type="verify-number"
                label="verify-number"
                variant="standard"
                fullWidth
                name="valid"
                value={inputs.valid}
                onChange={changeHandler}
                error={errors.validError}
                inputProps={{
                  autoComplete: "verify-number",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
              {errors.validError && (
                <MKTypography variant="caption" color="error" fontWeight="light">
                  회원가입 인증번호를 다시 확인해주세요
                </MKTypography>
              )}
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                name="password"
                value={inputs.password}
                onChange={changeHandler}
                error={errors.passwordError}
              />
              {errors.passwordError && (
                <MKTypography variant="caption" color="error" fontWeight="light">
                  The password must be of at least 8 characters
                </MKTypography>
              )}
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                type="password"
                label="Confirm Password"
                variant="standard"
                fullWidth
                name="confirmPass"
                value={inputs.confirmPass}
                onChange={changeHandler}
                error={errors.confirmPassError}
              />
              {errors.confirmPassError && (
                <MKTypography variant="caption" color="error" fontWeight="light">
                  The passwords must match
                </MKTypography>
              )}
            </MKBox>
            {errors.roleError && (
              <MKTypography variant="caption" color="error" fontWeight="light">
                You must choose a role
              </MKTypography>
            )}
            <MKBox display="flex" alignItems="center" ml={-1}>
              <Checkbox name="agree" id="agree" onChange={changeHandler} />
              <InputLabel
                variant="standard"
                fontWeight="regular"
                color="text"
                sx={{ lineHeight: "1.5", cursor: "pointer" }}
                htmlFor="agree"
              >
                &nbsp;&nbsp;I agree to the&nbsp;
              </InputLabel>
              <MKTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MKTypography>
            </MKBox>
            {errors.agreeError && (
              <MKTypography variant="caption" color="error" fontWeight="light">
                You must agree to the Terms and Conditions
              </MKTypography>
            )}
            {errors.emailTaken && (
              <MKTypography variant="caption" color="error" fontWeight="light">
                The email address has already been taken
              </MKTypography>
            )}
            <MKBox mt={3} mb={1}>
              <MKButton
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
                onClick={() => sendEmail_complete()}
              >
                sign up
              </MKButton>
            </MKBox>
            <MKBox mt={3} mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
                Already have an account?{" "}
                <MKTypography
                  component={Link}
                  to="/authentication/sign-in/simple"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  sign In
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </SimpleLayout2>
  );
}

export default SignUpSimple;
