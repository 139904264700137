import * as React from "react";
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { IconButton } from "@mui/material";
// import Divider from "@mui/material/Divider";
// import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";

import { useContext } from "react";
import routes_logout from "routes_logout";
import DefaultNavbarLogOut from "examples/Navbars/DefaultNavbarLogOut";
import { AuthContext } from "context";

const style_bottom = {
  py: 2.3,
  width: "100%",
  borderRadius: 0,
  border: "1px solid",
  borderColor: "divider",
  backgroundColor: "background.paper",
  mt: 20,
};

function Contents5() {
  const authContext = useContext(AuthContext);

  return (
    <>
      {authContext.isAuthenticated ? (
        <>
          <DefaultNavbarLogOut routes={routes_logout} sticky />
        </>
      ) : (
        <>
          <DefaultNavbar routes={routes} sticky />
        </>
      )}
      <Container>
        <Grid container spacing={3} item xs={10} lg={8} mx="auto">
          <MKBox flexDirection={{ xs: "column", md: "row" }}>
            <MKTypography
              // component="h6"
              variant="body2"
              color="primary"
              opacity={0.7}
              textTransform="none"
              fontWeight="bold"
              mt={40}
              style={{ userSelect: "none" }}
            >
              Knowledge base
            </MKTypography>
            <MKTypography variant="h2" mb={3} color="black" style={{ userSelect: "none" }}>
              Unlocking Medical Innovation: The Critical Role of Clinical Research and Real-World
              Evidence
            </MKTypography>
            <MKTypography
              variant="body1_w"
              color="black"
              style={{ userSelect: "none" }}
              align="justify"
            >
              <br />
              <br />
              Hey there, medical innovators! Lyndon here, your trusty data scientist from DATAIZE.
              Today, we&apos;re diving into the world of clinical research, the beating heart of
              medical advancement. But we&apos;re not just scratching the surface; we&apos;ll be
              exploring the significance of clinical research through the lens of Real-World Data
              (RWD) and Real-World Evidence (RWE).
              <br />
              <br />
              So, what exactly is clinical research? In a nutshell, it&apos;s any research involving
              human participants aimed at uncovering new ways to prevent, detect, or treat disease.
              It&apos;s the crucial bridge between groundbreaking lab discoveries and real-world
              patient care. Without clinical research, we wouldn&apos;t have the life-saving
              treatments and medical devices we rely on today.
              <br />
              <br />
              But here&apos;s where things get really exciting. In recent years, there&apos;s been a
              seismic shift in how we approach clinical research, and it&apos;s all thanks to the
              rise of RWD and RWE. RWD is the data collected outside of traditional clinical trials,
              from sources like electronic health records, claims data, and even mobile health apps.
              When analyzed, this data becomes RWE - the clinical evidence that reflects how
              treatments perform in the real world.
              <br />
              <br />
              Why is this such a game-changer? Well, traditional clinical trials often have strict
              eligibility criteria, which means they don&apos;t always represent the diverse patient
              populations seen in everyday clinical practice. RWE, on the other hand, gives us a
              more comprehensive picture of how treatments work across a wider range of patients.
              This is especially crucial for rare diseases or pediatric patients, who are often
              underrepresented in clinical trials.
              <br />
              <br />
              But the potential of RWD and RWE goes even further. By harnessing the power of
              artificial intelligence to analyze vast amounts of real-world data, we could unlock
              the door to truly personalized medicine. Imagine being able to predict how an
              individual patient will respond to a treatment based on their unique characteristics.
              That&apos;s the kind of revolutionary impact RWD and RWE could have on patient care.
              <br />
              <br />
              Of course, there are challenges to overcome, like ensuring data quality and protecting
              patient privacy. But if we can navigate these hurdles, the future of clinical research
              looks incredibly bright. The key will be finding the right balance between traditional
              clinical trials and RWE, leveraging the strengths of both to drive medical innovation
              forward.
              <br />
              <br />
              So there you have it, folks - a whirlwind tour of clinical research and the exciting
              frontier of RWD and RWE. I hope this post has given you a newfound appreciation for
              the vital role clinical research plays in advancing medicine and improving patient
              lives. Join me next time as we take a deeper dive into the nuts and bolts of clinical
              trial design and methodology.
              <br />
              <br />
              Until then, stay curious, stay innovative, and most importantly, stay healthy!
            </MKTypography>
          </MKBox>
        </Grid>
      </Container>
      <List sx={{ ...style_bottom }}>
        <ListItem>
          <ListItemText>
            {" "}
            <Container Width={3}>
              <Grid container spacing={0} display="flex-start" justifyContent="space-between">
                <Link path to="/blogs/bloglist/contents4">
                  <IconButton aria-label="Proceed">
                    <ArrowBackIcon />
                    <MKTypography variant="body2">Previous Contents</MKTypography>
                  </IconButton>
                </Link>
                <Link path to="/blogs/bloglist/contents6">
                  <IconButton aria-label="Proceed">
                    <MKTypography variant="body2">Next Contents</MKTypography>
                    <ArrowForwardIcon />
                  </IconButton>
                </Link>
              </Grid>
            </Container>
          </ListItemText>
        </ListItem>
      </List>
      {/* <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox> */}
    </>
  );
}

export default Contents5;
