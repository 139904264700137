import { useContext, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultNavbarLogOut from "examples/Navbars/DefaultNavbarLogOut";
//import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import routes_logout from "routes_logout";
import { useNavigate } from "react-router-dom";
//import footerRoutes from "footer.routes";

// Auth
// import AuthService from "services/auth-service";
// import getId from "services/helper-service";
import Cookies from "js-cookie";
import { AuthContext } from "context";

import homeimage1 from "assets/images/home-image1.svg";
import homeimage2 from "assets/images/home-image2.svg";

function Presentation() {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    // let isMounted = true;
    const token = localStorage.getItem("token");
    Cookies.set("token", token, { expires: 7, secure: true });
    return () => {
      // isMounted = false;
    };
  }, []);

  const handleClick = () => {
    if (!isAuthenticated) {
      // Confirm 대화상자 사용
      const isConfirmed = confirm("로그인이 필요한 기능입니다. 로그인 페이지로 이동하시겠습니까?");
      if (isConfirmed) {
        // 사용자가 확인을 누르면 navigate 함수 호출
        navigate("/authentication/sign-in/simple");
      }
    } else {
      window.open("https://www.dataize.io/aiscopedashboard", "_blank");
    }
  };

  return (
    <>
      {authContext.isAuthenticated ? (
        <>
          <DefaultNavbarLogOut routes={routes_logout} sticky />
          <MKBox
            // minHeight="75vh"
            width="100%"
            sx={{
              //backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Grid container item xs={12} lg={12} justifyContent="center">
              <MKTypography
                variant="h1"
                color="black"
                textAlign="center"
                fontWeight={500}
                px={{ xs: 12, lg: 12 }}
                mt={25}
                mb={0}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["2xl"],
                  },
                })}
              >
                The simplest way to extract Field of interest from <br /> Real-World Data{" "}
              </MKTypography>
            </Grid>
            <Grid container item xs={12} lg={12} justifyContent="center">
              <MKTypography
                variant="body1"
                color="black"
                textAlign="center"
                mt={3}
                px={{ xs: 12, lg: 12 }}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["md"],
                  },
                })}
              >
                Introducing the AI-Scope series – your solution for fast and intuitive data
                extraction. <br /> It&apos;s the efficient, user-friendly tool you&apos;ve been
                searching for.
              </MKTypography>
            </Grid>
            <Grid container item justifyContent="center">
              <MKBox
                display="flex"
                justify-content="flex-start"
                flexDirection={{ xs: "column", md: "row" }}
              >
                <MKBox
                  ml={{ xs: 0, md: 40 }}
                  component="img"
                  src={homeimage1}
                  sx={{ height: "341px", width: "auto" }}
                />
                <MKBox mt={{ xs: 0, md: 20 }} mb={{ xs: 10, md: 0 }} ml={{ xs: 12, md: -10 }}>
                  <MKButton
                    // variant="gradient"
                    color="info"
                    size="small"
                    component="a"
                    sx={{ mt: 0, mb: 0 }}
                    onClick={handleClick}
                  >
                    <MKTypography
                      variant="body1_1"
                      color="white"
                      textAlign="center"
                      px={{ xs: 1, lg: 1 }}
                      py={{ xs: 0.5, lg: 0.5 }}
                      sx={({ breakpoints, typography: { size } }) => ({
                        [breakpoints.down("md")]: {
                          fontSize: size["md"],
                        },
                      })}
                    >
                      Try Pro Free for 14 Days
                    </MKTypography>
                  </MKButton>
                </MKBox>
                <MKBox
                  ml={{ xs: 0, md: 20 }}
                  mt={{ xs: 0, md: -15 }}
                  component="img"
                  src={homeimage2}
                  sx={{ height: "410px", width: "auto" }}
                />
              </MKBox>
            </Grid>
          </MKBox>
          {/* <MKBox ml={20} component="img" src={homeimage2} sx={{ height: "410px", width: "auto" }} /> */}
        </>
      ) : (
        <>
          <DefaultNavbar routes={routes} sticky />
          <MKBox
            // minHeight="75vh"
            width="100%"
            sx={{
              //backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Grid container item xs={12} lg={12} justifyContent="center">
              <MKTypography
                variant="h1"
                color="black"
                textAlign="center"
                fontWeight={500}
                px={{ xs: 12, lg: 12 }}
                mt={25}
                mb={0}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["2xl"],
                  },
                })}
              >
                The simplest way to extract Field of interest from <br /> Real-World Data{" "}
              </MKTypography>
            </Grid>
            <Grid container item xs={12} lg={12} justifyContent="center">
              <MKTypography
                variant="body1"
                color="black"
                textAlign="center"
                mt={3}
                px={{ xs: 12, lg: 12 }}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["md"],
                  },
                })}
              >
                Introducing the AI-Scope series – your solution for fast and intuitive data
                extraction. <br /> It&apos;s the efficient, user-friendly tool you&apos;ve been
                searching for.
              </MKTypography>
            </Grid>
            <Grid container item justifyContent="center">
              <MKBox
                display="flex"
                justify-content="flex-start"
                flexDirection={{ xs: "column", md: "row" }}
              >
                <MKBox
                  ml={{ xs: 0, md: 40 }}
                  component="img"
                  src={homeimage1}
                  sx={{ height: "341px", width: "auto" }}
                />
                <MKBox mt={{ xs: 0, md: 20 }} mb={{ xs: 10, md: 0 }} ml={{ xs: 12, md: -10 }}>
                  <MKButton
                    // variant="gradient"
                    color="info"
                    size="small"
                    component="a"
                    sx={{ mt: 0, mb: 0 }}
                    onClick={handleClick}
                  >
                    <MKTypography
                      variant="body1_1"
                      color="white"
                      textAlign="center"
                      px={{ xs: 1, lg: 1 }}
                      py={{ xs: 0.5, lg: 0.5 }}
                      sx={({ breakpoints, typography: { size } }) => ({
                        [breakpoints.down("md")]: {
                          fontSize: size["md"],
                        },
                      })}
                    >
                      Try Pro Free for 14 Days
                    </MKTypography>
                  </MKButton>
                </MKBox>
                <MKBox
                  ml={{ xs: 0, md: 20 }}
                  mt={{ xs: 0, md: -15 }}
                  component="img"
                  src={homeimage2}
                  sx={{ height: "410px", width: "auto" }}
                />
              </MKBox>
            </Grid>
          </MKBox>
          {/* <MKBox ml={20} component="img" src={homeimage2} sx={{ height: "410px", width: "auto" }} /> */}
        </>
      )}
    </>
  );
}

export default Presentation;
