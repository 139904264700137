import { useEffect, useRef, useContext } from "react";

// rellax
import Rellax from "rellax";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// import DefaultFooter from "examples/Footers/DefaultFooter";

import DefaultNavbarLogOut from "examples/Navbars/DefaultNavbarLogOut";
import { AuthContext } from "context";

// About Us page sections
import Posts from "pages/Blogs/BlogList/sections/Posts";
// import Support from "pages/Blogs/BlogList/sections/Support";

// Routes
import routes from "routes";
import routes_logout from "routes_logout";
// import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/blog_bg3.jpg";

function Post() {
  const headerRef = useRef(null);

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);

  const authContext = useContext(AuthContext);
  // const { isAuthenticated } = useContext(AuthContext);/

  return (
    <>
      {authContext.isAuthenticated ? (
        <>
          <DefaultNavbarLogOut routes={routes_logout} sticky />
          <MKBox
            ref={headerRef}
            minHeight="50vh"
            width="100%"
            sx={{
              backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                `${linearGradient(
                  rgba(gradients.dark.main, 0.5),
                  rgba(gradients.dark.state, 0.5)
                )}, url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Container>
              <Grid container item xs={12} lg={7} justifyContent="center" flexDirection="column">
                <MKTypography
                  variant="h2"
                  color="white"
                  style={{ userSelect: "none" }}
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Blogs
                </MKTypography>
              </Grid>
            </Container>
          </MKBox>
          <Card mt={5}>
            <Posts />
          </Card>
        </>
      ) : (
        <>
          <DefaultNavbar routes={routes} sticky />
          <MKBox
            ref={headerRef}
            minHeight="50vh"
            width="100%"
            sx={{
              backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                `${linearGradient(
                  rgba(gradients.dark.main, 0.5),
                  rgba(gradients.dark.state, 0.5)
                )}, url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Container>
              <Grid container item xs={12} lg={7} justifyContent="center" flexDirection="column">
                <MKTypography
                  variant="h2"
                  color="white"
                  style={{ userSelect: "none" }}
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Blogs
                </MKTypography>
              </Grid>
            </Container>
          </MKBox>
          <Card mt={5}>
            <Posts />
          </Card>
        </>
      )}
    </>
  );
}

export default Post;
