import { useContext, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

import AuthService from "services/auth-service";
import { AuthContext } from "context";

// Authentication layout components
import SimpleLayout from "pages/Authentication/components/SimpleLayout";
// import Separator from "pages/Authentication/components/Separator";
// import Socials from "pages/Authentication/components/Socials";

function SignInSimple() {
  const authContext = useContext(AuthContext);
  const [rememberMe, setRememberMe] = useState(false);
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    emailError: false,
    passwordError: false,
    credentialsErros: false,
    textError: "",
  });

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const mailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (inputs.email.trim().length === 0 || !inputs.email.trim().match(mailFormat)) {
      setErrors({ ...errors, emailError: true });
      return;
    }

    if (inputs.password.trim().length < 6) {
      setErrors({ ...errors, passwordError: true });
      return;
    }

    const newUser = { email: inputs.email, password: inputs.password };

    const myData = {
      data: {
        type: "token",
        attributes: { ...newUser },
      },
    };

    try {
      const response = await AuthService.login(myData);
      authContext.login(response.access_token, response.refresh_token);
      window.location.href = "https://www.dataize.io/aiscopedashboard";
    } catch (res) {
      // 오류 메시지가 있는지 확인하고, 그렇지 않으면 기본 오류 메시지를 설정합니다.
      let errorMessage = "An unknown error occurred."; // 기본 오류 메시지
      if (Object.prototype.hasOwnProperty.call(res, "message")) {
        errorMessage = res.message;
      } else if (
        res.errors &&
        res.errors.length > 0 &&
        Object.prototype.hasOwnProperty.call(res.errors[0], "detail")
      ) {
        errorMessage = res.errors[0].detail;
      }
      // 오류 상태를 업데이트합니다.
      setErrors({ ...errors, credentialsErrors: true, textError: errorMessage });
    }
    // try {
    //   const response = await AuthService.login(myData);
    //   authContext.login(response.access_token, response.refresh_token);
    // } catch (res) {
    //   if (Object.prototype.hasOwnProperty.call(res, "message")) {
    //     setErrors({ ...errors, credentialsErros: true, textError: res.message });
    //   } else {
    //     setErrors({ ...errors, credentialsErros: true, textError: res.errors[0].detail });
    //   }
    // }

    return () => {
      setInputs({
        email: "",
        password: "",
      });

      setErrors({
        emailError: false,
        passwordError: false,
        credentialsErros: false,
        textError: "",
      });
    };
  };

  return (
    <SimpleLayout>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          pt={2.5}
          pb={2.875}
          px={2.5}
          textAlign="center"
          lineHeight={1}
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MKTypography>
          <MKTypography variant="button" color="white">
            Welcome back
          </MKTypography>
        </MKBox>
        <MKBox p={3}>
          <MKBox component="form" role="form" method="POST" onSubmit={submitHandler}>
            <MKBox mb={2}>
              <MKInput
                type="email"
                label="Email"
                fullWidth
                name="email"
                value={inputs.email}
                onChange={changeHandler}
                error={errors.emailError}
              />
              {errors.emailError && (
                <MKTypography variant="caption" color="error" fontWeight="light">
                  Check your information
                </MKTypography>
              )}
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                type="password"
                label="Password"
                fullWidth
                name="password"
                value={inputs.password}
                onChange={changeHandler}
                error={errors.passwordError}
              />
              {errors.passwordError && (
                <MKTypography variant="caption" color="error" fontWeight="light">
                  Check your information
                </MKTypography>
              )}
            </MKBox>
            <MKBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MKTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MKTypography>
            </MKBox>
            <MKBox mt={2} mb={1}>
              <MKButton variant="gradient" color="info" fullWidth type="submit">
                sign in
              </MKButton>
            </MKBox>
            {/* <Separator />
            <Socials /> */}
            <MKBox mt={3} textAlign="center">
              <MKTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MKTypography
                  component={Link}
                  to="/authentication/sign-up/simple"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </SimpleLayout>
  );
}

export default SignInSimple;
