import * as React from "react";
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { IconButton } from "@mui/material";
// import Divider from "@mui/material/Divider";
// import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";

const style_bottom = {
  py: 2.3,
  width: "100%",
  borderRadius: 0,
  border: "1px solid",
  borderColor: "divider",
  backgroundColor: "background.paper",
  mt: 20,
};

function Contents10() {
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <Container>
        <Grid container spacing={3} item xs={10} lg={8} mx="auto">
          <MKBox flexDirection={{ xs: "column", md: "row" }}>
            <MKTypography
              // component="h6"
              variant="body2"
              color="primary"
              opacity={0.7}
              textTransform="none"
              fontWeight="bold"
              mt={40}
              style={{ userSelect: "none" }}
            >
              Knowledge base
            </MKTypography>
            <MKTypography variant="h1" mb={3} color="black" style={{ userSelect: "none" }}>
              USCDI+ Cancer
            </MKTypography>
            <MKTypography
              variant="body1"
              color="black"
              style={{ userSelect: "none" }}
              align="justify"
            >
              <br /> <br />
              안녕하세요. David입니다. <br /> <br />
              지난 블로그 게시물 “Improving Cancer Care Through Better Electronic Health Records:
              Voluntary Commitments and Call to Action” 에서 알아보겠다고 한 “암 관련 핵심 데이터
              요소 표준화(USCDI+ Cancer)”에 대해서 오늘은 최대한 구체적으로 알아보고 관련된 내용을
              찾아볼 수 있는 링크를 알려드리려고 합니다. <br /> <br />
              암 관련 핵심 데이터 요소 표준화(USCDI+ Cancer)는 전자건강기록(EHR)에 포함되어야 할
              최소한의 암 관련 데이터 요소 세트를 정의한 것입니다. <br /> <br />
              USCDI+ Cancer에 포함된 주요 데이터 요소는 다음과 같습니다: <br /> <br />
              <ul>
                <li style={{ listStyle: "inside", marginLeft: "2em" }}>
                  환자 인구통계학적 정보 (나이, 인종, 민족 등)
                </li>
                <li style={{ listStyle: "inside", marginLeft: "2em" }}>
                  암 관련 검사 및 영상 결과 (병리검사, 유전자검사, CT/MRI 등)
                </li>
                <li style={{ listStyle: "inside", marginLeft: "2em" }}>
                  수술, 방사선, 화학요법 등 치료 내역
                </li>
                <li style={{ listStyle: "inside", marginLeft: "2em" }}>
                  재발 여부 및 상태 추적 정보
                </li>
                <li style={{ listStyle: "inside", marginLeft: "2em" }}>가족력 및 유전체 정보</li>
                <li style={{ listStyle: "inside", marginLeft: "2em" }}>생존 및 예후 관련 정보</li>
              </ul>
              <br />이 표준은 ONC(건강IT국가조정본부), NIH(국립보건원), CMS(의료보험 및 메디케이드
              서비스 센터) 등 보건 당국이 주도하고 있으며, 구체적인 정보에 대한 링크는 아래와
              같습니다. <br /> <br /> USCDI+ Record - USCDI+ Service Portal 조금 더 구체적으로
              알아보기로 하겠습니다. 미국의 암 관련 핵심 데이터 요소 표준화, 즉 USCDI+ Cancer
              프로그램은 암 예방, 진단, 치료, 연구 및 관리를 개선하기 위해 실제 데이터(RWD) 요소를
              정의하는 것을 목표로 합니다. 이 프로그램은 미국 정부가 암 환자를 지원하는 데 기여하며,
              HHS(미국 보건복지부) 내 여러 기관 및 백악관의 Cancer Moonshot 프로젝트와의 공동 관심사
              및 책임 영역입니다. USCDI+ Cancer 프로그램은 NCI(국립암연구소)와
              ONC(국립보건정보기술조정국)가 CMS(의료보험 및 메디케이드 서비스 센터),
              CDC(질병통제예방센터), FDA(식품의약국)의 의견을 수렴하여 관리하며, 다음과 같은 사용
              사례에 초점을 맞춥니다: <br /> <br />
              <ul>
                <li style={{ listStyle: "inside", marginLeft: "2em" }}>임상 시험 모집 / 매칭</li>
                <li style={{ listStyle: "inside", marginLeft: "2em" }}>면역 관련</li>
                <li style={{ listStyle: "inside", marginLeft: "2em" }}>부작용(irAE) 추적</li>
                <li style={{ listStyle: "inside", marginLeft: "2em" }}>
                  암 등록부 데이터 수집의 효율성 및 시기 적절성 향상
                </li>
                <li style={{ listStyle: "inside", marginLeft: "2em" }}>
                  EOM(Enhancing Oncology Model) 정렬
                </li>
              </ul>
              <br /> Enhancing Oncology Model | CMS USCDI+는 기존 USCDI에 확장 운영되는 도메인 또는
              프로그램별 데이터 요소 목록의 식별 및 설정을 지원합니다. 이를 통해 특정 프로그램
              및/또는 사용 사례 요구 사항을 충족하기 위해 핵심 데이터를 넘어서는 상호 운용 가능한
              데이터 요소 목록의 사용을 진행, 조화, 발전시킬 수 있습니다. USCDI+는 협업, 조화,
              사양의 세 가지 핵심 원칙을 따르며, 공공 보건, 품질, 암, 행동 건강, 모성 건강 등 여러
              도메인을 포함합니다. <br /> <br /> EHR(Electronic Health Records, 전자 건강 기록)
              개발자들은 USCDI+ Cancer의 핵심이 되는 EOM 데이터 요소를 통합하여 전국적인 건강 정보
              교환을 지원하도록 독려받고 있습니다. 이러한 통합은 특히 농촌 및 소외 지역에서 암
              환자의 치료 조정 개선, 연구 결과의 가속화, 보다 효과적인 공중 보건 개입의 새로운
              가능성을 열어줍니다. <br /> <br /> 더 구체적인 정보를 원하시면 아래 링크를 참고하세요.
              오늘도 행복하게! <br /> <br /> United States Core Data for Interoperability (USCDI)
              USCDI+ | HealthIT.gov EHR developers adopt FHIR-based oncology standardization
            </MKTypography>
          </MKBox>
        </Grid>
      </Container>
      <List sx={{ ...style_bottom }}>
        <ListItem>
          <ListItemText>
            {" "}
            <Container Width={3}>
              <Grid container spacing={0} display="flex-start" justifyContent="space-between">
                <Link path to="/blogs/bloglist/contents9">
                  <IconButton aria-label="Proceed">
                    <ArrowBackIcon />
                    <MKTypography variant="body2">Previous Contents</MKTypography>
                  </IconButton>
                </Link>
                <IconButton aria-label="Proceed">
                  <MKTypography variant="body2">Next Contents</MKTypography>
                  <ArrowForwardIcon />
                </IconButton>
              </Grid>
            </Container>
          </ListItemText>
        </ListItem>
      </List>
      {/* <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox> */}
    </>
  );
}

export default Contents10;
