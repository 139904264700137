// import AboutUs from "layouts/pages/company/about-us";
import BlogList from "layouts/pages/blogs/bloglist";

const routes = [
  {
    name: "Blog",
    route: "/blogs/bloglist",
    component: <BlogList />,
  },
  {
    name: "Logout",
  },
];

export default routes;
