import * as React from "react";
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { IconButton } from "@mui/material";
// import Divider from "@mui/material/Divider";
// import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";

import { useContext } from "react";
import routes_logout from "routes_logout";
import DefaultNavbarLogOut from "examples/Navbars/DefaultNavbarLogOut";
import { AuthContext } from "context";

const style_bottom = {
  py: 2.3,
  width: "100%",
  borderRadius: 0,
  border: "1px solid",
  borderColor: "divider",
  backgroundColor: "background.paper",
  mt: 20,
};

function Contents4() {
  const authContext = useContext(AuthContext);

  return (
    <>
      {authContext.isAuthenticated ? (
        <>
          <DefaultNavbarLogOut routes={routes_logout} sticky />
        </>
      ) : (
        <>
          <DefaultNavbar routes={routes} sticky />
        </>
      )}
      <Container>
        <Grid container spacing={3} item xs={10} lg={8} mx="auto">
          <MKBox flexDirection={{ xs: "column", md: "row" }}>
            <MKTypography
              // component="h6"
              variant="body2"
              color="primary"
              opacity={0.7}
              textTransform="none"
              fontWeight="bold"
              mt={40}
              style={{ userSelect: "none" }}
            >
              Knowledge base
            </MKTypography>
            <MKTypography variant="h2" mb={3} color="black" style={{ userSelect: "none" }}>
              The Future is AI: Innovating Patient Cohorts!
            </MKTypography>
            <MKTypography
              variant="body1_w"
              color="black"
              style={{ userSelect: "none" }}
              align="justify"
            >
              <br />
              <br />
              What&apos;s good, folks? Lyndon here from DATAIZE with the final installment of our
              patient cohorts series. The title says it all - we`&apos;re talking solutions and
              innovations to revolutionize this process. So buckle up and pay attention, because
              this is the good stuff!
              <br />
              <br />
              As we&apos;ve covered, building robust patient cohorts is crucial but comes with a
              laundry list of hurdles - data quality issues, privacy minefields, representation
              challenges, and more. But have no fear, because AI is here to save the day! An
              advanced AI solution packing these key capabilities can help overcome those pesky pain
              points:
              <br />
              <br />
              <ul>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  1. Automated Data Cleanup
                  <br />
                  <br />
                  Using AI, we can automatically identify and correct incomplete, erroneous, or
                  inconsistent data. Hello, higher quality data for stronger analyses!
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  2. Natural Language Processing Prowess
                  <br />
                  <br />
                  NLP techniques allow us to extract valuable insights from unstructured sources
                  like medical records, clinical notes, and research papers. This enables
                  fine-tuning patient criteria and auto-identifying relevant cohorts.
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  3. Advanced Analytics Arsenal
                  <br />
                  <br />
                  Cutting-edge AI algorithms can uncover complex patterns and correlations in even
                  the most vast, intricate datasets. Essential for risk analysis, predictive
                  modeling, estimating treatment effects, and more.
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  4. Privacy and Security Muscle
                  <br />
                  <br />
                  AI solutions have to comply with data privacy laws/regs and handle sensitive
                  patient info ultra-securely through anonymization, pseudonymization, etc.
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  5. User-Friendly Interfaces
                  <br />
                  <br />
                  Easy-to-use interfaces and visualization tools are key so researchers can
                  seamlessly access data and understand those analysis outputs.
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  6. Scalability and Flexibility
                  <br />
                  <br />
                  These solutions must be able to scale for different data types/sizes and adapt to
                  emerging new sources or research needs.
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  7. Collaboration Capabilities
                  <br />
                  <br />
                  Enabling researchers to easily share data, analysis results, and collaborate is
                  crucial for increasing transparency and reproducibility.
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  8. Ethical AI Guidelines
                  <br />
                  <br />
                  Upholding ethical standards with transparent, unbiased AI decision-making
                  processes for all patient groups is non-negotiable.
                </li>
              </ul>
              So with capabilities like those, AI can seriously amp up cohort-building and
              streamline high-quality medical research.
              <br />
              <br />
              But that&apos;s not all! Here at DATAIZE, we&apos;re going a step beyond traditional
              NLP by leveraging the immense power of large language models (LLMs). Their advanced
              reasoning and prompt engineering abilities provide much more flexible, context-aware
              understanding compared to conventional NLP.
              <br />
              <br />
              This LLM approach allows us to explore a wide range of complex data sources, extract
              nuanced info from unstructured text, and dynamically analyze the interrelationships
              between disparate data points - all through carefully crafted prompts.
              <br />
              <br />
              Here&apos;s a quick rundown of how we&apos;re using LLMs to construct robust patient
              cohorts:
              <br />
              <br />
              <ul>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  1) Data Prep: Identify and extract data from sources like EMRs/EHRs, clinical
                  notes, research papers (while ensuring rigorous data privacy).
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  2) Info Extraction: Construct prompts to extract specific details like diagnoses,
                  treatments, etc. From unstructured text. Leverage the LLM&apos;s reasoning
                  abilities for complex clinical situations.
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  3) Relationship Analysis: Use prompts to understand interrelationships between
                  different variables and data points within patient data.
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  4) Ethics & Validation: Ensure data de-identification and verify that LLM outputs
                  align with established medical knowledge via expert review.
                </li>

                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  5) Cohort Building & Analysis: Create prompts for establishing cohorts based on
                  inclusion/exclusion criteria. Then run additional analysis prompts to extract
                  relevant insights.
                </li>
              </ul>
              While insanely powerful, LLMs still require human validation - their outputs
              can&apos;t be blindly accepted as gospel for medical decision-making. But they provide
              an amazing tool for understanding unstructured data and exploring hypotheses.
              <br />
              <br />
              So there you have it - the future of patient cohort innovation is AI-powered,
              especially using advanced language models. With solutions like these, we can propel
              medical research and public health initiatives.
              <br />
              <br />
              Thanks for following along this whole series! Feel free to reach out if you have any
              other questions. Until next time, stay crispy, my friends!
            </MKTypography>
          </MKBox>
        </Grid>
      </Container>
      <List sx={{ ...style_bottom }}>
        <ListItem>
          <ListItemText>
            {" "}
            <Container Width={3}>
              <Grid container spacing={0} display="flex-start" justifyContent="space-between">
                <Link path to="/blogs/bloglist/contents3">
                  <IconButton aria-label="Proceed">
                    <ArrowBackIcon />
                    <MKTypography variant="body2">Previous Contents</MKTypography>
                  </IconButton>
                </Link>
                <Link path to="/blogs/bloglist/contents5">
                  <IconButton aria-label="Proceed">
                    <MKTypography variant="body2">Next Contents</MKTypography>
                    <ArrowForwardIcon />
                  </IconButton>
                </Link>
              </Grid>
            </Container>
          </ListItemText>
        </ListItem>
      </List>
      {/* <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox> */}
    </>
  );
}

export default Contents4;
