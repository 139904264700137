import BlogList from "layouts/pages/blogs/bloglist";
import SignInSimplePage from "layouts/authentication/sign-in/simple";
import SignUpSimplePage from "layouts/authentication/sign-up/simple";

const routes = [
  {
    name: "Blog",
    route: "/blogs/bloglist",
    component: <BlogList />,
  },
  {
    name: "SignIn",
    route: "/authentication/sign-in/simple",
    component: <SignInSimplePage />,
  },
  {
    name: "SignUp",
    route: "/authentication/sign-up/simple",
    component: <SignUpSimplePage />,
  },
];

export default routes;
