import * as React from "react";
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { IconButton } from "@mui/material";
// import Divider from "@mui/material/Divider";
// import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";

import { useContext } from "react";
import routes_logout from "routes_logout";
import DefaultNavbarLogOut from "examples/Navbars/DefaultNavbarLogOut";
import { AuthContext } from "context";

const style_bottom = {
  py: 2.3,
  width: "100%",
  borderRadius: 0,
  border: "1px solid",
  borderColor: "divider",
  backgroundColor: "background.paper",
  mt: 20,
};

function Contents1() {
  const authContext = useContext(AuthContext);

  return (
    <>
      {authContext.isAuthenticated ? (
        <>
          <DefaultNavbarLogOut routes={routes_logout} sticky />
        </>
      ) : (
        <>
          <DefaultNavbar routes={routes} sticky />
        </>
      )}
      <Container>
        <Grid container spacing={3} item xs={10} lg={8} mx="auto">
          <MKBox flexDirection={{ xs: "column", md: "row" }}>
            <MKTypography
              // component="h6"
              variant="body1"
              color="primary"
              opacity={0.7}
              textTransform="none"
              fontWeight="bold"
              mt={40}
              style={{ userSelect: "none" }}
            >
              Knowledge base
            </MKTypography>
            <MKTypography variant="h2" mb={3} color="black" style={{ userSelect: "none" }}>
              Why Patient Cohorts Matter - A Biggie in Medical Research
            </MKTypography>
            <MKTypography
              variant="body1_w"
              color="black"
              style={{ userSelect: "none" }}
              align="justify"
            >
              <br />
              <br />
              Hey there, folks! Lyndon here, the Data Scientist at DATAIZE.
              <br />
              <br />
              Let me give you a quick rundown on what we do. Think of medical data as the crude oil
              - DATAIZE is the refinery that extracts and processes it. We&apos;re cooking up some
              seriously cool products aka AI-Scope that combine cutting-edge AI models, big data
              tech for large-scale extraction, and cloud computing to help you build rock-solid
              patient cohorts. And why&apos;s that important? Well, having a robust patient cohort
              is vital for knocking medical research out of the park and getting those thesis
              publications into the big league journals.
              <br />
              <br />
              So let&apos;s dive into a multi-part series breaking it all down. First up, we&apos;re
              covering why patient cohorts are such a big deal and an absolute must-have. Then
              we&apos;ll walk through how to actually build these cohorts, the hurdles you might
              face, and finally, solutions for resolving those pesky pain points.
              <br />
              <br />
              Patient cohort studies are game-changers for developing overarching disease management
              strategies, evaluating treatments, and uncovering what really drives diseases. These
              studies follow a population with a specific condition or treatment over time, giving
              researchers an in-depth look at the natural disease progression, treatment outcomes,
              risk factors, and more.
              <br />
              <br />
              Today, we&apos;re zeroing in on the importance and mega-necessity of patient cohorts.
              Here are the key reasons why they matter:
              <br />
              <br />
              <ul>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  1. Leveling Up Disease Understanding
                  <br />
                  <br />
                  Cohorts are critical for grasping the real-world paths diseases take and how they
                  unfold over time. Observing the long-term impacts on patients illuminates the true
                  nature and variations of the disease.
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  2. Scoring Treatment Effectiveness
                  <br />
                  <br />
                  These cohorts allow us to compare and truly evaluate the effectiveness of
                  different treatments head-to-head. This evidence is pivotal for proving new
                  therapies work or optimizing existing ones.
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  3. Identifying Risk Factors
                  <br />
                  <br />
                  By pinpointing risk factors for diseases, we can develop smart prevention
                  strategies and enable early intervention for high-risk populations.
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  4. Crafting Public Health Policies
                  <br />
                  <br />
                  Cohort study findings provide a solid foundation for building effective public
                  health policies and disease management game plans to improve overall health
                  outcomes.
                </li>
              </ul>
              And that&apos;s just the tip of the iceberg - patient cohorts are research
              heavy-hitters that pack a serious punch.
              <br />
              <br />
              Appreciate you tuning in! Next time, I&apos;ll be dishing out the deets on actually
              building these all-important patient cohorts.
              <br />
              <br />
              Stay tuned and keep it crispy!
            </MKTypography>
          </MKBox>
        </Grid>
      </Container>
      <List sx={{ ...style_bottom }}>
        <ListItem>
          <ListItemText>
            {" "}
            <Container Width={3}>
              <Grid container spacing={0} display="flex-start" justifyContent="space-between">
                <Link path to="/blogs/bloglist/contents0">
                  <IconButton aria-label="Proceed">
                    <ArrowBackIcon />
                    <MKTypography variant="body2">Previous Contents</MKTypography>
                  </IconButton>
                </Link>
                <Link path to="/blogs/bloglist/contents2">
                  <IconButton aria-label="Proceed">
                    <MKTypography variant="body2">Next Contents</MKTypography>
                    <ArrowForwardIcon />
                  </IconButton>
                </Link>
              </Grid>
            </Container>
          </ListItemText>
        </ListItem>
      </List>
      {/* <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox> */}
    </>
  );
}

export default Contents1;
