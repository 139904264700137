import * as React from "react";
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { IconButton } from "@mui/material";
// import Divider from "@mui/material/Divider";
// import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";

import { useContext } from "react";
import routes_logout from "routes_logout";
import DefaultNavbarLogOut from "examples/Navbars/DefaultNavbarLogOut";
import { AuthContext } from "context";

const style_bottom = {
  py: 2.3,
  width: "100%",
  borderRadius: 0,
  border: "1px solid",
  borderColor: "divider",
  backgroundColor: "background.paper",
  mt: 20,
};

function Contents3() {
  const authContext = useContext(AuthContext);

  return (
    <>
      {authContext.isAuthenticated ? (
        <>
          <DefaultNavbarLogOut routes={routes_logout} sticky />
        </>
      ) : (
        <>
          <DefaultNavbar routes={routes} sticky />
        </>
      )}
      <Container>
        <Grid container spacing={3} item xs={10} lg={8} mx="auto">
          <MKBox flexDirection={{ xs: "column", md: "row" }}>
            <MKTypography
              // component="h6"
              variant="body2"
              color="primary"
              opacity={0.7}
              textTransform="none"
              fontWeight="bold"
              mt={40}
              style={{ userSelect: "none" }}
            >
              Knowledge base
            </MKTypography>
            <MKTypography variant="h2" mb={3} color="black" style={{ userSelect: "none" }}>
              Patient Cohorts: The Struggle is Real
            </MKTypography>
            <MKTypography
              variant="body1_w"
              color="black"
              style={{ userSelect: "none" }}
              align="justify"
            >
              <br />
              <br />
              Hey there, Lyndon from DATAIZE here! Looking out the window on this gorgeous spring
              day with the cherry blossoms in full bloom - doesn&apos;t get much better than this,
              am I right? Hope you&apos;re all soaking up the nice weather.
              <br />
              <br />
              For today&apos;s edition, we&apos;re diving into the nitty-gritty difficulties and
              pain points of constructing those all-important patient cohorts. As we covered last
              time, they&apos;re vital research tools, but there are some definite hurdles to clear.
              Let&apos;s go through the biggest ones:
              <br />
              <br />
              <ul>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  1. Data Quality and Access Woes
                  <br />
                  <br />
                  Getting your hands on high-quality, relevant patient data can be like finding a
                  needle in a haystack. And even when you do obtain it, that data is often
                  incomplete, inconsistent, or an overall mess.
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  2. Privacy Minefields
                  <br />
                  <br />
                  Adhering to all the privacy regulations surrounding patient data is a massive
                  challenge. Researchers have to jump through hoops to get proper consent, handle
                  data ultra-securely, and ensure they&apos;re checking every ethical box.
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  3. Data Overload
                  <br />
                  <br />
                  Especially for large-scale cohorts, the technical tasks of effectively storing,
                  managing, and analyzing massive amounts of data can get extremely complex and
                  costly. Not a trivial undertaking.
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  4. Representation Dilemmas
                  <br />
                  <br />
                  Your study cohorts may not be fully representative of the broader population,
                  limiting how much you can generalize and apply the results across different
                  groups. What&apos;s true for one population may not fly for another.
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  5. Long-Term Commitment Issues
                  <br />
                  <br />
                  For those long-running cohort studies, keeping participants engaged and following
                  up over long periods is TOUGH. You&apos;ll inevitably have drop-offs and lose
                  contact with some folks over time.
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  6. Resource Constraints
                  <br />
                  <br />
                  Let&apos;s be real - cohort studies require immense resources in time, money, and
                  effort. We&apos;re talking years of work from study design to data collection,
                  analysis, reporting results, and everything in between.
                </li>
              </ul>
              Even with all those hurdles, patient cohorts remain invaluable for medical research
              and public health. That&apos;s why here at DATAIZE, we&apos;re cooking up products
              that make extracting the necessary data easier and faster through cutting-edge tech
              convergence.
              <br />
              <br />
              In the next post, I&apos;ll be sharing more about those hard-hitting solutions to
              overcome these pain points. So stay tuned!
              <br />
              <br />
              Thanks for tuning in, and enjoy that spring weather!
            </MKTypography>
          </MKBox>
        </Grid>
      </Container>
      <List sx={{ ...style_bottom }}>
        <ListItem>
          <ListItemText>
            {" "}
            <Container Width={3}>
              <Grid container spacing={0} display="flex-start" justifyContent="space-between">
                <Link path to="/blogs/bloglist/contents2">
                  <IconButton aria-label="Proceed">
                    <ArrowBackIcon />
                    <MKTypography variant="body2">Previous Contents</MKTypography>
                  </IconButton>
                </Link>
                <Link path to="/blogs/bloglist/contents4">
                  <IconButton aria-label="Proceed">
                    <MKTypography variant="body2">Next Contents</MKTypography>
                    <ArrowForwardIcon />
                  </IconButton>
                </Link>
              </Grid>
            </Container>
          </ListItemText>
        </ListItem>
      </List>
      {/* <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox> */}
    </>
  );
}

export default Contents3;
