import * as React from "react";
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { IconButton } from "@mui/material";
// import Divider from "@mui/material/Divider";
// import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";

import { useContext } from "react";
import routes_logout from "routes_logout";
import DefaultNavbarLogOut from "examples/Navbars/DefaultNavbarLogOut";
import { AuthContext } from "context";

const style_bottom = {
  py: 2.3,
  width: "100%",
  borderRadius: 0,
  border: "1px solid",
  borderColor: "divider",
  backgroundColor: "background.paper",
  mt: 20,
};

function Contents8() {
  const authContext = useContext(AuthContext);

  return (
    <>
      {authContext.isAuthenticated ? (
        <>
          <DefaultNavbarLogOut routes={routes_logout} sticky />
        </>
      ) : (
        <>
          <DefaultNavbar routes={routes} sticky />
        </>
      )}
      <Container>
        <Grid container spacing={3} item xs={10} lg={8} mx="auto">
          <MKBox flexDirection={{ xs: "column", md: "row" }}>
            <MKTypography
              // component="h6"
              variant="body2"
              color="primary"
              opacity={0.7}
              textTransform="none"
              fontWeight="bold"
              mt={40}
              style={{ userSelect: "none" }}
            >
              Knowledge base
            </MKTypography>
            <MKTypography variant="h2" mb={3} color="black" style={{ userSelect: "none" }}>
              Exploring the Future of Clinical Research: The Impact of AI, Big Data, and Wearables
            </MKTypography>
            <MKTypography
              variant="body1_w"
              color="black"
              style={{ userSelect: "none" }}
              align="justify"
            >
              <br />
              <br />
              Hey there, visionaries, and innovators! Lyndon here, your trusty data science guide
              from DATAIZE. We&apos;ve covered a lot of ground in this series, from the fundamentals
              of clinical research to ethical considerations. Today, as we wrap up, we&apos;re going
              to take a thrilling glimpse into the future of clinical research and explore how
              cutting-edge technologies are revolutionizing the field. And, of course, we&apos;ll be
              keeping our Real-World Data (RWD) and Real-World Evidence (RWE) goggles on throughout
              this exciting journey.
              <br />
              <br />
              In the wake of the Fourth Industrial Revolution, artificial intelligence (AI), big
              data, wearables, and other advanced technologies are rapidly infiltrating the
              healthcare sector. These technological breakthroughs are poised to bring about seismic
              shifts in clinical research methodologies, particularly in studies leveraging RWD and
              RWE.
              <br />
              <br />
              First up, AI has the potential to be a game-changer in analyzing vast amounts of RWD,
              uncovering meaningful patterns and insights. By harnessing machine learning
              algorithms, we could predict complex disease trajectories and propose personalized
              treatment plans tailored to individual patients. This brings us one step closer to
              realizing the promise of precision medicine.
              <br />
              <br />
              Big data technologies play a pivotal role in integrating and standardizing RWD from
              diverse sources. By linking and analyzing data from electronic health records (EHRs),
              claims databases, genomic information, and wearable devices, we can gain a
              comprehensive understanding of disease natural history and treatment responses. This,
              in turn, enhances the quality and validity of RWE.
              <br />
              <br />
              Wearable technologies are set to revolutionize the way we collect and monitor
              patients&apos; health data in real-time. RWD collected through smartwatches and patch
              sensors can boost clinical trial participants&apos; adherence and enable efficient
              data capture. This has the potential to dramatically reduce the cost and time required
              for clinical studies.
              <br />
              <br />
              Moreover, these technologies pave the way for novel research approaches like virtual
              clinical trials. By leveraging remote monitoring and digital biomarkers, participants
              can engage in clinical trials without physically visiting a hospital. This could
              contribute to more patient-centric and inclusive clinical research practices.
              <br />
              <br />
              Of course, there are challenges to overcome when implementing these technologies, such
              as data security, privacy protection, and ethical considerations. Regulatory
              frameworks for applying new technologies in clinical research will also need to be
              established. However, if we navigate these challenges wisely, clinical research will
              become more sophisticated, efficient, and tailored to individual needs.
              <br />
              <br />
              So there you have it, folks - a sneak peek into the future of clinical research, with
              RWD and RWE at the forefront. AI, big data, wearables, and other advanced technologies
              are set to transform research methodologies and accelerate the advent of precision
              medicine. As we embrace these technological innovations, we must prioritize the rights
              and safety of research participants.
              <br />
              <br />
              Thank you for joining me on this incredible series. I hope our journey has sparked
              thought-provoking conversations about the importance, value, and future of clinical
              research. DATAIZE remains committed to pioneering innovative, RWD and RWE-driven
              clinical research.
              <br />
              <br />
              Wishing you all health, happiness, and a future full of groundbreaking discoveries.
              Until next time!
            </MKTypography>
          </MKBox>
        </Grid>
      </Container>
      <List sx={{ ...style_bottom }}>
        <ListItem>
          <ListItemText>
            {" "}
            <Container Width={3}>
              <Grid container spacing={0} display="flex-start" justifyContent="space-between">
                <Link path to="/blogs/bloglist/contents7">
                  <IconButton aria-label="Proceed">
                    <ArrowBackIcon />
                    <MKTypography variant="body2">Previous Contents</MKTypography>
                  </IconButton>
                </Link>
                <IconButton aria-label="Proceed">
                  <MKTypography variant="body2">Next Contents</MKTypography>
                  <ArrowForwardIcon />
                </IconButton>
              </Grid>
            </Container>
          </ListItemText>
        </ListItem>
      </List>
      {/* <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox> */}
    </>
  );
}

export default Contents8;
