import { useEffect, useContext } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

import { setupAxiosInterceptors } from "services/interceptor";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";
import AboutUs from "layouts/pages/company/about-us";
import Pricing from "layouts/pages/company/pricing";
import BlogContents0 from "layouts/pages/blogs/blog-article/contents0";
import BlogContents1 from "layouts/pages/blogs/blog-article/contents1";
import BlogContents2 from "layouts/pages/blogs/blog-article/contents2";
import BlogContents3 from "layouts/pages/blogs/blog-article/contents3";
import BlogContents4 from "layouts/pages/blogs/blog-article/contents4";
import BlogContents5 from "layouts/pages/blogs/blog-article/contents5";
import BlogContents6 from "layouts/pages/blogs/blog-article/contents6";
import BlogContents7 from "layouts/pages/blogs/blog-article/contents7";
import BlogContents8 from "layouts/pages/blogs/blog-article/contents8";
import BlogContents9 from "layouts/pages/blogs/blog-article/contents9";
import BlogContents10 from "layouts/pages/blogs/blog-article/contents10";

// Material Kit 2 PRO React routes
import routes from "routes";

import { AuthContext } from "context";
// import { getPermissions } from "config/Permissions";
// import AuthService from "services/auth-service";

export default function App() {
  const { pathname } = useLocation();
  const authContext = useContext(AuthContext);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  // if the token expired or other errors it logs out and goes to the login page
  const navigate = useNavigate();
  setupAxiosInterceptors(() => {
    authContext.logout();
    navigate("/auth/login");
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="/" element={<Presentation />} />
        <Route path="/pages/company/about-us" element={<AboutUs />} />
        <Route path="/pages/company/pricing" element={<Pricing />} />
        <Route path="/pages/company/pricing" element={<Navigate to="/pages/company/pricing" />} />
        <Route path="/pages/company/about-us" element={<Navigate to="/pages/company/about-us" />} />
        {/* Blog Contents list */}

        <Route path="/blogs/bloglist/contents0" element={<BlogContents0 />} />
        <Route path="/blogs/bloglist/contents1" element={<BlogContents1 />} />
        <Route path="/blogs/bloglist/contents2" element={<BlogContents2 />} />
        <Route path="/blogs/bloglist/contents3" element={<BlogContents3 />} />
        <Route path="/blogs/bloglist/contents4" element={<BlogContents4 />} />
        <Route path="/blogs/bloglist/contents5" element={<BlogContents5 />} />
        <Route path="/blogs/bloglist/contents6" element={<BlogContents6 />} />
        <Route path="/blogs/bloglist/contents7" element={<BlogContents7 />} />
        <Route path="/blogs/bloglist/contents8" element={<BlogContents8 />} />
        <Route path="/blogs/bloglist/contents9" element={<BlogContents9 />} />
        <Route path="/blogs/bloglist/contents10" element={<BlogContents10 />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  );
}
