import * as React from "react";
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { IconButton } from "@mui/material";
// import Divider from "@mui/material/Divider";
// import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";

import { useContext } from "react";
import routes_logout from "routes_logout";
import DefaultNavbarLogOut from "examples/Navbars/DefaultNavbarLogOut";
import { AuthContext } from "context";

const style_bottom = {
  py: 2.3,
  width: "100%",
  borderRadius: 0,
  border: "1px solid",
  borderColor: "divider",
  backgroundColor: "background.paper",
  mt: 20,
};

function Contents2() {
  const authContext = useContext(AuthContext);

  return (
    <>
      {authContext.isAuthenticated ? (
        <>
          <DefaultNavbarLogOut routes={routes_logout} sticky />
        </>
      ) : (
        <>
          <DefaultNavbar routes={routes} sticky />
        </>
      )}
      <Container>
        <Grid container spacing={3} item xs={10} lg={8} mx="auto">
          <MKBox flexDirection={{ xs: "column", md: "row" }}>
            <MKTypography
              // component="h6"
              variant="body2"
              color="primary"
              opacity={0.7}
              textTransform="none"
              fontWeight="bold"
              mt={40}
              style={{ userSelect: "none" }}
            >
              Knowledge base
            </MKTypography>
            <MKTypography variant="h2" mb={3} color="black" style={{ userSelect: "none" }}>
              Building Patient Cohorts 101
            </MKTypography>
            <MKTypography
              variant="body1_w"
              color="black"
              style={{ userSelect: "none" }}
              align="justify"
            >
              <br />
              <br />
              What&apos;s up, folks? Lyndon here, the data wizard at DATAIZE.
              <br />
              <br />
              Today, I&apos;m breaking down how to construct those all-important patient cohorts.
              The process can definitely vary depending on the study goals, the data you&apos;re
              working with, and the specific research questions. But typically, here&apos;s how it
              goes:
              <br />
              <br />
              <ul>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  1. Define Your Study Mission
                  <br />
                  <br />
                  First up, you gotta establish clear research questions about the disease,
                  condition, treatment, or whatever it is you&apos;re investigating. At this stage,
                  it&apos;s crucial to clearly define the study&apos;s purpose and hypotheses
                  you&apos;re testing.
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  2. Decide Who&apos;s In and Who&apos;s Out
                  <br />
                  <br />
                  Next, you set the criteria for which patients get invited to the cohort party and
                  who doesn&apos;t make the cut. These inclusion/exclusion criteria should reflect
                  the specific characteristics needed to answer your research questions (age,
                  gender, disease stage, treatment history, etc.).
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  3. Data Source Hunting
                  <br />
                  <br />
                  Time to determine where you&apos;ll be mining that sweet, sweet patient data from.
                  Electronic health records, clinic registries, population health databases - lots
                  of options on the table. When choosing data sources, make sure to consider
                  quality, accessibility, and how well it represents your target population.
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  4. Data Extraction and Cleanup
                  <br />
                  <br />
                  With your criteria set, extract the relevant data and give it a good scrubbing to
                  remove any incomplete or iffy stuff. This step ensures consistency and transforms
                  the data into an analysis-friendly format if needed.
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  5. Cohort Construction
                  <br />
                  <br />
                  Now it&apos;s time to build those cohorts using your extracted, refined data.
                  Define the patient population you&apos;ll be following over a set period and
                  gather all the relevant info for each patient (diagnoses, treatments, outcomes,
                  etc.).
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  6. Analysis Game Plan
                  <br />
                  <br />
                  Select a statistical methodology that can meaningfully analyze your cohort data
                  and provide answers to your research questions. You&apos;ll choose the right
                  analysis model and determine how to transform variables and normalize data.
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  7. Ethics Check
                  <br />
                  <br />
                  Any study using patient data has to follow strict privacy and ethics guidelines.
                  If required, you&apos;ll need approval from the Research Ethics Board.
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  8. Analysis and Interpretation
                  <br />
                  <br />
                  Run your analyses using your chosen methodology and interpret those results. This
                  is where you put your hypotheses to the test and (hopefully) find solutions to
                  your research questions.
                </li>
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  9. Share Your Findings
                  <br />
                  <br />
                  Once you&apos;ve got results, publish them for the world and share any relevant
                  datasets to pave the way for future research. You can publish in a journal or
                  present at a conference.
                </li>
              </ul>
              Of course, this process can be adapted based on the specific situation. Additional
              steps may be needed depending on those research objectives. Building solid patient
              cohorts is complex, so you&apos;ll want a diverse team of experts (doctors, data
              scientists, ethicists, etc.) to collaborate.
              <br />
              <br />
              Hopefully that demystifies it a bit! Let me know if you have any other questions.
              <br />
              <br />
              Stay crispy, my friends!
            </MKTypography>
          </MKBox>
        </Grid>
      </Container>
      <List sx={{ ...style_bottom }}>
        <ListItem>
          <ListItemText>
            {" "}
            <Container Width={3}>
              <Grid container spacing={0} display="flex-start" justifyContent="space-between">
                <Link path to="/blogs/bloglist/contents1">
                  <IconButton aria-label="Proceed">
                    <ArrowBackIcon />
                    <MKTypography variant="body2">Previous Contents</MKTypography>
                  </IconButton>
                </Link>
                <Link path to="/blogs/bloglist/contents3">
                  <IconButton aria-label="Proceed">
                    <MKTypography variant="body2">Next Contents</MKTypography>
                    <ArrowForwardIcon />
                  </IconButton>
                </Link>
              </Grid>
            </Container>
          </ListItemText>
        </ListItem>
      </List>
      {/* <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox> */}
    </>
  );
}

export default Contents2;
