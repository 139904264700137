import { useState } from "react";
import Pagination from "@mui/material/Pagination";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import DefaultBlogCard from "examples/Cards/BlogCards/DefaultBlogCard";
import creator from "assets/images/dataize_logo.png";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import MKButton from "components/MKButton";
// import MKButton from "components/MKButton";
// import MuiLink from "@mui/material/Link";

const posts = [
  {
    // image: author1,
    category: { color: "primary", label: "Knowledge base" },
    title: "Exploring the Future of Clinical Research: The Impact of AI, Big Data, and Wearables",
    description:
      "Hey there, visionaries, and innovators! Lyndon here, your trusty data science guide from DATAIZE. We've covered a lot of ground in this series, from the fundamentals of clinical research to ethical considerations.",
    author: {
      image: creator,
      name: "Lyndon",
      date: "Posted on 04 May",
    },
    action: { type: "internal", route: "/blogs/bloglist/contents8" },
  },
  {
    // image: author1,
    category: { color: "primary", label: "Knowledge base" },
    title:
      "Ethical Considerations in Clinical Research: Navigating Participant Rights and Data Privacy",
    description:
      "Hey there, research revolutionaries! Lyndon here, your data science companion from DATAIZE. Today, we're tackling a crucial topic in clinical research: ethical considerations.",
    author: {
      image: creator,
      name: "Lyndon",
      date: "Posted on 03 May",
    },
    action: { type: "internal", route: "/blogs/bloglist/contents7" },
  },
  {
    // image: author1,
    category: { color: "primary", label: "Knowledge base" },
    title:
      "Advancing Clinical Research: Exploring Study Designs and Methodologies in the Age of Real-World Data",
    description:
      "Hey there, research enthusiasts! Lyndon, your data science sidekick from DATAIZE, back again with another deep dive into the world of clinical research. Last time, we covered the fundamentals and significance of clinical research ... ",
    author: {
      image: creator,
      name: "Lyndon",
      date: "Posted on 02 May",
    },
    action: { type: "internal", route: "/blogs/bloglist/contents6" },
  },
  {
    // image: author1,
    category: { color: "primary", label: "Knowledge base" },
    title:
      "Unlocking Medical Innovation: The Critical Role of Clinical Research and Real-World Evidence",
    description:
      "Hey there, medical innovators! Lyndon here, your trusty data scientist from DATAIZE. Today, we're diving into the world of clinical research, the beating heart of medical advancement. But we're not just scratching the surface; we'll be exploring the significance of clinical research ...",
    author: {
      image: creator,
      name: "Lyndon",
      date: "Posted on 01 May",
    },
    action: { type: "internal", route: "/blogs/bloglist/contents5" },
  },
  {
    // image: author1,
    category: { color: "primary", label: "Knowledge base" },
    title: "The Future is AI: Innovating Patient Cohorts!",
    description:
      "What's good, folks? Lyndon here from DATAIZE with the final installment of our patient cohorts series. The title says it all - we`re talking solutions and innovations to revolutionize this process. So buckle up and pay attention, because this is the good stuff!",
    author: {
      image: creator,
      name: "Lyndon",
      date: "Posted on 24 April",
    },
    action: { type: "internal", route: "/blogs/bloglist/contents4" },
  },
  {
    // image: author1,
    category: { color: "primary", label: "Knowledge base" },
    title: "Patient Cohorts: The Struggle is Real",
    description:
      "Hey there, Lyndon from DATAIZE here! Looking out the window on this gorgeous spring day with the cherry blossoms in full bloom - doesn't get much better than this, am I right? Hope you're all soaking up the nice weather.",
    author: {
      image: creator,
      name: "Lyndon",
      date: "Posted on 22 April",
    },
    action: { type: "internal", route: "/blogs/bloglist/contents3" },
  },
  {
    // image: author1,
    category: { color: "primary", label: "Knowledge base" },
    title: "Building Patient Cohorts 101",
    description:
      "What's up, folks? Lyndon here, the data wizard at DATAIZE. Today, I'm breaking down how to construct those all-important patient cohorts.  We're cooking up some seriously cool products aka AI-Scope that combine cutting-edge AI models, big data tech for large-scale extraction, and cloud computing to help you build rock-solid patient cohorts.",
    author: {
      image: creator,
      name: "Lyndon",
      date: "Posted on 15 April",
    },
    action: { type: "internal", route: "/blogs/bloglist/contents2" },
  },
  {
    // image: author1,
    category: { color: "primary", label: "Knowledge base" },
    title: "Why Patient Cohorts Matter - A Biggie in Medical Research",
    description:
      "Hey there, folks! Lyndon here, the Data Scientist at DATAIZE. Let me give you a quick rundown on what we do. Think of medical data as the crude oil - DATAIZE is the refinery that extracts and processes it.",
    author: {
      image: creator,
      name: "Lyndon",
      date: "Posted on 08 April",
    },
    action: { type: "internal", route: "/blogs/bloglist/contents1" },
  },
  {
    // image: author1,
    category: { color: "primary", label: "Knowledge base" },
    title:
      "Grooving' the Medical Data Revolution: Extracting Structured Data from Unstructured with AI-Scope",
    description:
      "Hey there, tech trailblazers! Lyndon here, your friendly neighborhood data scientist at DATAIZE. Picture this: if medical data were crude oil, DATAIZE would be the upstream platform extracting that black gold.",
    author: {
      image: creator,
      name: "Lyndon",
      date: "Posted on 01 April",
    },
    action: { type: "internal", route: "/blogs/bloglist/contents0" },
  },
];

const Posts = () => {
  const [page, setPage] = useState(1);
  const itemsPerPage = 6;

  const handleChange = (event, value) => {
    setPage(value);
  };

  const indexOfLastPost = page * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  //★ Email Title Function
  const [Email, setEmail] = useState({
    email: "",
  });

  const EmailChange = (e) => {
    const { value } = e.target;

    // 값이 유효한 경우에만 state 업데이트
    setEmail((prevState) => ({
      ...prevState,
      email: value, // state의 key를 올바르게 지정합니다.
    }));
    return true;
  };

  const EmailUpload = () => {
    const dataWithFileName = {
      fileName: Email.email, // 파일 이름 추가
    };
    const jsonData = JSON.stringify(dataWithFileName);
    fetch("https://www.dataize.io/data-api/newsEmail/upload", {
      method: "POST",
      body: jsonData,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => response.json()); // 응답 처리

    return alert("뉴스레터 구독이 신청되었습니다.");
  };

  return (
    <Grid
      sx={{ lg: 8, xs: 12, mt: 6, display: "flex", justifyContent: "center" }}
      flexDirection={{ xs: "column", md: "row" }}
    >
      <MKBox component="section" py={1} ml={2}>
        <Container>
          <Grid container lg={8}>
            {currentPosts.map((posts, index) => (
              <Grid item key={index} mb={{ xs: 3, lg: 3 }} ml={3} spacing={3}>
                <DefaultBlogCard
                  category={posts.category}
                  title={posts.title}
                  description={posts.description}
                  author={posts.author}
                  action={posts.action}
                />
              </Grid>
            ))}
            <Grid
              item
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              mt={5}
              mb={10}
            >
              <Pagination
                count={Math.ceil(posts.length / itemsPerPage)}
                page={page}
                onChange={handleChange}
                color="info"
                sx={{ display: "flex", justifyContent: "center" }}
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Grid item xs={12} lg={3} ml={{ xs: 7, lg: -50 }} mr={{ xs: 3 }} mb={{ xs: 5 }} zIndex={1000}>
        <MKTypography color="black" variant="h6" mb={1} mt={3}>
          Join Our Newsletter
        </MKTypography>
        <Divider style={{ background: "black" }} />
        <MKInput
          fullWidth
          label="Enter your E-mail.."
          name="email"
          value={Email.email}
          onChange={EmailChange}
          InputProps={{
            endAdornment: (
              <MKTypography mr={-2} onClick={EmailUpload} variant="body2" px={0.5}>
                <MKButton color="info">-&gt;</MKButton>
              </MKTypography>
            ),
          }}
        />
        <MKTypography color="black" variant="h6" mt={7}>
          Recent Post
        </MKTypography>
        <Divider style={{ background: "black" }} />
        <Link path to="/blogs/bloglist/contents8">
          <MKTypography color="black" variant="body2">
            Exploring the Future of Clinical Research
          </MKTypography>
        </Link>
        <Link path to="/blogs/bloglist/contents7">
          <MKTypography color="black" variant="body2">
            Ethical Considerations in Clinical Research
          </MKTypography>
        </Link>
        <Link path to="/blogs/bloglist/contents6">
          <MKTypography color="black" variant="body2">
            Advancing Clinical Research
          </MKTypography>
        </Link>
        <Link path to="/blogs/bloglist/contents5">
          <MKTypography color="black" variant="body2">
            Unlocking Medical Innovation
          </MKTypography>
        </Link>
        <Link path to="/blogs/bloglist/contents4">
          <MKTypography color="black" variant="body2">
            The Future is AI: Innovating Patient Cohorts!
          </MKTypography>
        </Link>
        <Link path to="/blogs/bloglist/contents3">
          <MKTypography color="black" variant="body2">
            Patient Cohorts: The Struggle is Real
          </MKTypography>
        </Link>
        <Link path to="/blogs/bloglist/contents2">
          <MKTypography color="black" variant="body2">
            Building Patient Cohorts 101
          </MKTypography>
        </Link>
        <Link path to="/blogs/bloglist/contents1">
          <MKTypography color="black" variant="body2">
            Why Patient Cohorts Matter
          </MKTypography>
        </Link>
        <Link path to="/blogs/bloglist/contents0">
          <MKTypography color="black" variant="body2">
            Groovin&apos; the Medical Data Revolution
          </MKTypography>
        </Link>
      </Grid>
    </Grid>
  );
};

export default Posts;
