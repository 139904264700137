import * as React from "react";
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { IconButton } from "@mui/material";
// import Divider from "@mui/material/Divider";
// import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";

const style_bottom = {
  py: 2.3,
  width: "100%",
  borderRadius: 0,
  border: "1px solid",
  borderColor: "divider",
  backgroundColor: "background.paper",
  mt: 20,
};

function Contents1() {
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <Container>
        <Grid container spacing={3} item xs={10} lg={8} mx="auto">
          <MKBox flexDirection={{ xs: "column", md: "row" }}>
            <MKTypography
              // component="h6"
              variant="body2"
              color="primary"
              opacity={0.7}
              textTransform="none"
              fontWeight="bold"
              mt={40}
              style={{ userSelect: "none" }}
            >
              Knowledge base
            </MKTypography>
            <MKTypography
              variant="h1"
              mb={3}
              color="black"
              style={{ userSelect: "none" }}
              algin="justify"
            >
              Why is the White House so aggressive in the United States to improve the
              interoperability of the Electronic Medical/Health Record System (EMR/EHR)?
            </MKTypography>
            <MKTypography
              variant="body1"
              color="black"
              style={{ userSelect: "none" }}
              align="justify"
            >
              <br />
              <br />
              지난 블로그 게시물에서 알아보겠다고 한 것 중에 미국은 도대체 왜
              전자의무기록/건강기록(EMR/EHR) 시스템의 상호운용성 향상을 백악관까지 나서서 하고 있는
              것 인지에 대한 이유를 나름대로 정리해보았습니다.
              <br />
              <br />
              1. 환자 안전 및 의료 질 향상
              <ul>
                <br />
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  각기 다른 EMR/EHR 시스템 간에 데이터가 원활히 공유되지 않으면 의사가 환자의 전체
                  의료 정보를 파악하기 어려워집니다. 이는 중복 검사, 약물 부작용 등의 위험을 높이고
                  최적의 치료를 저해할 수 있습니다.{" "}
                </li>
              </ul>
              2. 의료비 절감
              <ul>
                <br />
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  상호운용성이 높아지면 불필요한 반복 검사를 줄일 수 있고, 효율적인 의사 결정으로
                  치료비 절감이 가능합니다.
                </li>
              </ul>
              3. 원격의료 및 팀 기반 의료 지원
              <ul>
                <br />
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  환자 데이터가 여러 의료기관에 분산되어 있으면 원격의료나 협력 진료에 어려움이
                  있습니다. 상호운용성으로 이를 극복할 수 있습니다.
                </li>
              </ul>
              4. 공중 보건 및 의료 연구 지원
              <ul>
                <br />
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  모든 EMR/EHR 데이터가 통합되면 질병 추세 모니터링, 임상시험 환자 모집 등 연구에
                  활용될 수 있습니다.
                </li>
              </ul>
              5. 환자 참여 향상
              <ul>
                <br />
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  환자가 자신의 기록에 접근하고 이해할 수 있으면 건강 관리에 더 잘 참여할 수
                  있습니다.
                </li>
              </ul>
              이처럼 EMR/EHR 상호운용성은 환자 안전, 의료 효율성, 비용 절감, 연구 혁신 등을 위해
              매우 중요하기 때문에 백악관 차원의 대응이 필요했던 것 같습니다. 그런데 실제로 이것이
              전부일까요? 사실 우리가 아주 쉬운 사실을 간과하고 있는 것이 있습니다. 네, 맞습니다.
              전자의무/건강기록(EMR/EHR) 시스템의 상호운용성 문제 이면에는 좀 더 근본적인 이슈가
              있습니다.
              <br />
              <br />
              1. 의료IT 벤더들의 독점 체제
              <ul>
                <br />
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  주요 EMR/EHR 벤더들이 자사 제품의 표준을 고수하고 경쟁사 제품과의 호환성을
                  제한함으로써 상호운용성이 저해되는 상황입니다. 이는 벤더 lock-in 효과를 노리는
                  것입니다.
                </li>
              </ul>
              2. 보건의료 데이터 표준의 부재
              <ul>
                <br />
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  업계 전반에 걸쳐 데이터 형식, 용어, 코딩 등의 표준이 부족해 정보 교환에 어려움이
                  있습니다. 정부 주도로 표준화를 추진해야 하는 이유입니다.
                </li>
              </ul>
              3. 보안 및 프라이버시 우려
              <ul>
                <br />
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  환자 데이터를 다른 기관과 공유할 때 정보 유출이나 오남용에 대한 우려가 있어
                  상호운용성 도입이 더뎠습니다.
                </li>
              </ul>
              4. 초기 투자 부담
              <ul>
                <br />
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  레거시 시스템을 새로운 표준에 맞추기 위해서는 상당한 비용과 노력이 필요한데, 이를
                  꺼리는 의료기관들이 많았습니다.
                </li>
              </ul>
              5. 변화에 대한 저항
              <ul>
                <br />
                <li style={{ listStyle: "none", marginBottom: "2em" }}>
                  의료진과 행정 담당자들이 새로운 시스템과 프로세스 변화를 싫어하는 문화적 요인도
                  작용했습니다.
                </li>
              </ul>
              따라서 백악관 차원의 개입은 단순한 기술 이슈를 넘어 산업계 이해관계, 보안, 비용, 변화
              관리 등 복합적 문제를 해결하기 위한 것이라고 볼 수 있습니다. 재미있게 읽으셨나요?
              오늘도 행복하게! ^^
            </MKTypography>
          </MKBox>
        </Grid>
      </Container>
      <List sx={{ ...style_bottom }}>
        <ListItem>
          <ListItemText>
            {" "}
            <Container Width={3}>
              <Grid container spacing={0} display="flex-start" justifyContent="space-between">
                <Link path to="/blogs/bloglist/contents8">
                  <IconButton aria-label="Proceed">
                    <ArrowBackIcon />
                    <MKTypography variant="body2">Previous Contents</MKTypography>
                  </IconButton>
                </Link>
                <Link path to="/blogs/bloglist/contents10">
                  <IconButton aria-label="Proceed">
                    <MKTypography variant="body2">Next Contents</MKTypography>
                    <ArrowForwardIcon />
                  </IconButton>
                </Link>
              </Grid>
            </Container>
          </ListItemText>
        </ListItem>
      </List>
      {/* <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox> */}
    </>
  );
}

export default Contents1;
