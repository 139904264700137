import * as React from "react";
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { IconButton } from "@mui/material";
// import Divider from "@mui/material/Divider";
// import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
// import footerRoutes from "footer.routes";

import { useContext } from "react";
import routes_logout from "routes_logout";
import DefaultNavbarLogOut from "examples/Navbars/DefaultNavbarLogOut";
import { AuthContext } from "context";

const style_bottom = {
  py: 2.3,
  width: "100%",
  borderRadius: 0,
  border: "1px solid",
  borderColor: "divider",
  backgroundColor: "background.paper",
  mt: 20,
};

function Contents0() {
  const authContext = useContext(AuthContext);

  return (
    <>
      {authContext.isAuthenticated ? (
        <>
          <DefaultNavbarLogOut routes={routes_logout} sticky />
        </>
      ) : (
        <>
          <DefaultNavbar routes={routes} sticky />
        </>
      )}
      <Container>
        <Grid container spacing={3} item xs={10} lg={8} mx="auto">
          <MKBox flexDirection={{ xs: "column", md: "row" }}>
            <MKTypography
              // component="h6"
              variant="body1"
              color="primary"
              opacity={0.7}
              textTransform="none"
              fontWeight="bold"
              mt={40}
              style={{ userSelect: "none" }}
            >
              Knowledge base
            </MKTypography>
            <MKTypography variant="h2" mb={3} color="black" style={{ userSelect: "none" }}>
              Groovin&apos; the Medical Data Revolution: Extracting Structured Data from
              Unstructured with AI-Scope
            </MKTypography>
            <MKTypography
              variant="body1_w"
              color="black"
              style={{ userSelect: "none" }}
              align="justify"
            >
              <br />
              <br />
              Hey there, tech trailblazers!
              <br />
              Lyndon here, your friendly neighborhood data scientist at DATAIZE.
              <br />
              <br />
              Picture this: if medical data were crude oil, DATAIZE would be the upstream platform
              extracting that black gold. And our specialty? We&apos;re all about diving deep into
              the untapped potential of unstructured data (aka free text).
              <br />
              <br />
              Now, get this - a whopping 80% of the data generated in hospitals is unstructured!
              We&apos;re talking initial and follow-up records from various departments using EMRs,
              pathology reports, radiology reports, nursing records, discharge summaries... you name
              it. Each author has their own unique style and format, resulting in a smorgasbord of
              data stored in a way that&apos;s not exactly analysis-friendly. Even if hospitals have
              fancy clinical data warehouses or data lakes for research purposes, exporting these
              records often leads to a jumbled mess in an Excel cell. Without some serious data
              preprocessing, it&apos;s pretty much useless.
              <br />
              <br />
              But imagine if we could swiftly and effortlessly transform this unstructured data into
              a structured format and seamlessly integrate it back into EMRs, clinical data
              warehouses, or data lakes. It would be a game-changer for medical research and
              clinical decision-making!
              <br />
              <br />
              That&apos;s where our rockstar team comes in. We&apos;re harnessing the power of
              artificial intelligence (like NLP and advanced inference capabilities of large
              language models), big data tech (for extracting and managing massive datasets), and
              cloud computing (for easy data management). Our mission? To alchemize that
              unstructured free text into glorious structured data. And we&apos;re not just stopping
              there - we&apos;re standardizing and structuring it according to the minimal Common
              Oncology Data Elements (mCODE) developed by the American Society of Clinical Oncology
              (ASCO) and the mCODE Extraction Framework created by MITRE Corporation to enhance data
              usability and support clinical research and decision-making. Plus, with mCODE using
              FHIR, the healthcare data exchange standard, it&apos;s a win-win for data
              standardization and interoperability.
              <br />
              <br />
              We&apos;ve dubbed our brainchild the &quot;AI-Enabled mCODE Extraction
              Framework.&quot; And to showcase its potential for driving healthcare innovation,
              we&apos;re building patient cohorts under the codename &quot;AI-Scope.&quot;
              We&apos;ve kicked things off with &quot;Onco AI-Scope,&quot; focusing on extracting
              structured data from unstructured sources in the breast cancer domain.
              <br />
              <br />
              Thanks for sticking with me through this wild ride!
              <br />
              In the coming posts, we&apos;ll dive deeper into how Onco AI-Scope is revolutionizing
              patient cohort creation, starting with the very definition of a patient cohort. Stay
              tuned for more!
              <br />
              <br />
              Keep the groove alive, data adventurers!
            </MKTypography>
          </MKBox>
        </Grid>
      </Container>
      <List sx={{ ...style_bottom }}>
        <ListItem>
          <ListItemText>
            {" "}
            <Container Width={3}>
              <Grid container spacing={0} display="flex-start" justifyContent="space-between">
                <IconButton aria-label="Proceed">
                  <ArrowBackIcon />
                  <MKTypography variant="body2">Previous Contents</MKTypography>
                </IconButton>
                <Link path to="/blogs/bloglist/contents1">
                  <IconButton aria-label="Proceed">
                    <MKTypography variant="body2">Next Contents</MKTypography>
                    <ArrowForwardIcon />
                  </IconButton>
                </Link>
              </Grid>
            </Container>
          </ListItemText>
        </ListItem>
      </List>
      {/* <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox> */}
    </>
  );
}

export default Contents0;
